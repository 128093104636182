<template>
  <div class="app members-by-count">
    <Navigation/>
    <PanelSidebar/>
    <div class="panel-content members-by-count-content">
      <Head :title="title" :type="type" :docPath="docPath" :placeholder1="placeholder1" :placeholder2="placeholder2" @submit="setDate"/>
      <div class="members-by-count-content-in" :style="{visibility: list === null ? 'hidden' : 'visible'}">
        <List ref="listRef" :total="total" :list="list" @reload="query"/>
      </div>
    </div>
  </div>
</template>

<script>
import Head from '../../components/PanelHead.vue'
import List from '../../components/MembersList.vue'
import Navigation from '../../components/PanelNav.vue'
import PanelSidebar from '../../components/PanelSidebar.vue'
import { useI18n } from 'vue-i18n'
import { useDialog } from '../../utils/dialog.js'
import { membersByCountApi } from '../../api/index.js'
import { reactive, toRefs, ref } from '@vue/reactivity'

export default {
  name: 'MembersByCount',
  components: {
    Head,
    List,
    Navigation,
    PanelSidebar
  },
  setup() {
    const { t } = useI18n()
    const listRef = ref(null)
    const Dialog = useDialog()
    const data = reactive({
      title: t('membersByCount.head.title'),
      type: 'number',
      docPath: t('membersByCount.head.docPath'),
      placeholder1: t('membersByCount.head.placeholder1'),
      placeholder2: t('membersByCount.head.placeholder2'),

      start: 0,
      end: 0,

      list: null,
      total: 0
    })

    const setDate = (start, end) => {
      if (start === '' || end === '') {
        Dialog({
          content: t('membersByCount.setDate.error'),
          btns: [{ text: t('ok') }]
        })
        return
      }
      data.start = start
      data.end = end
      listRef.value.query()
    }

    const query = (sort, order, limit, offset) => {
      Dialog({ type: 'loading' })
      data.list = null
      membersByCountApi({
        sort: sort,
        order: order,
        limit: limit,
        offset: offset,
        start: data.start,
        end: data.end
      }).then(succ => {
        if (succ.code === 10000) {
          data.list = []
          data.total = succ.data.total
          succ.data.list.forEach((item, index) => {
            data.list.push({
              checkbox: false,
              id: item.id,
              avatar: item.avatar,
              username: item.username,
              nickname: item.nickname,
              level: item.level,
              total: item.total,
              joinTime: item.joinTime,
              latestMsgTime: item.latestMsgTime
            })
          })
        }
      }).finally(() => {
        Dialog({ remove: true })
      })
    }

    return { ...toRefs(data), t, listRef, query, setDate }
  }
}
</script>
